import { createReducer, on } from '@ngrx/store';
import * as pageActions from '../actions/additions.action';

export interface AdditionsState {
  town: string | null;
  street: string | null;
  additions: any[];
  loaded: boolean;
  loading: boolean;
  success?: boolean;
  error?: any;
  addressError?: any;
}

export const initialState: AdditionsState = {
  town: null,
  street: null,
  additions: [],
  loaded: false,
  loading: false,
};

export const AdditionsReducer = createReducer(
  initialState,
  on(pageActions.LoadValidAdditions, (state: AdditionsState) => {
    return {
      ...state,
      additions: [],
      loading: true,
      loaded: false,
    };
  }),
  on(pageActions.LoadValidAdditionsSuccess, (state: AdditionsState, { data }) => {
    // console.log(data);
    return {
      ...state,
      additions: data.additions,
      street: data.street,
      town: data.town,
      loading: false,
      loaded: true,
      addressError: null,
    };
  }),
  on(pageActions.LoadValidAdditionsFail, (state: AdditionsState, { error }) => {
    return {
      ...state,
      street: null,
      town: null,
      loading: false,
      loaded: false,
      addressChecked: true,
      addressEntitled: false,
      addressError: error,
    };
  })
);

export const getValidAdditions = (state: AdditionsState) => state.additions;
export const getValidAdditionsLoading = (state: AdditionsState) => state.loading;
export const getValidAdditionsLoaded = (state: AdditionsState) => state.loaded;
export const getAdditionsTown = (state: AdditionsState) => state.town;
export const getAdditionsStreet = (state: AdditionsState) => state.street;
export const getAddressError = (state: AdditionsState) => state.addressError;
