import { createReducer, on } from '@ngrx/store';
import { WasteCardNumber } from '../../models';
import * as actions from '../actions/waste-card-number.action';
import * as utility from '../../../utility';

export interface WasteCardNumberState {
  number: WasteCardNumber | null;
  loaded: boolean;
  loading: boolean;
  initializing: boolean;
  initialized: boolean;
  error?: any;
}

export const initialState: WasteCardNumberState = {
  number: null,
  loaded: false,
  loading: false,
  initialized: false,
  initializing: false,
};

export const WasteCardNumberReducer = createReducer(
  initialState,
  on(actions.SetWasteCardNumber, (state: WasteCardNumberState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(actions.SetWasteCardNumberSuccess, (state: WasteCardNumberState, { number }) => {
    return {
      ...state,
      number,
      loading: false,
      loaded: true,
      error: undefined,
    };
  }),
  on(actions.SetWasteCardNumberFail, (state: WasteCardNumberState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(actions.InitWasteCardNumber, (state: WasteCardNumberState) => {
    return {
      ...state,
      initializing: true,
    };
  }),
  on(actions.InitWasteCardNumberSuccess, (state: WasteCardNumberState, { number }) => {
    return {
      ...state,
      number,
      loaded: number !== null,
      initializing: false,
      initialized: true,
    };
  }),
  on(actions.SetWasteCardNumberFail, (state: WasteCardNumberState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
      initializing: false,
      initialized: true,
    };
  }),
  on(actions.ClearWasteCardNumber, (state: WasteCardNumberState) => {
    return {
      ...state,
      number: null,
      loaded: false,
      loading: false,
      initialized: true,
      initializing: false,
      error: null,
    };
  })
);

export const getWasteCardNumber = (state: WasteCardNumberState) => state.number;
export const getWasteCardNumberLoading = (state: WasteCardNumberState) => state.loading;
export const getWasteCardNumberLoaded = (state: WasteCardNumberState) => state.loaded;
export const getWasteCardNumberInitializing = (state: WasteCardNumberState) => state.initializing;
export const getWasteCardNumberInitialized = (state: WasteCardNumberState) => state.initialized;
export const getWasteCardNumberError = (state: WasteCardNumberState) => state.error;
