import { createAction, props } from '@ngrx/store';
import { WasteCardNumber } from '../../models';

export const SetWasteCardNumber = createAction(
  '[Location] Set Waste Card Number',
  props<{ housenumber: number; addition: string; postalCode: string; number: WasteCardNumber }>()
);
export const SetWasteCardNumberSuccess = createAction('[Location] Set Waste Card Number Success', props<{ number: WasteCardNumber }>());
export const SetWasteCardNumberFail = createAction('[Location] Set Waste Card Number Fail', props<{ error: any }>());

export const ClearWasteCardNumber = createAction('[Location] Clear Waste Card Number');

export const InitWasteCardNumber = createAction('[Location] Initialize WasteCardNumber From Local Storage');
export const InitWasteCardNumberSuccess = createAction(
  '[Location] Initialize WasteCardNumber From Local Storage Success',
  props<{ number: WasteCardNumber | null }>()
);
export const InitWasteCardNumberFail = createAction(
  '[Location] Initialize WasteCardNumber From Local Storage Fail',
  props<{ error: any }>()
);
