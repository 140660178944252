import * as fromAdditions from './additions.reducer';
import * as fromAdresses from './address.reducer';
import * as fromWasteCardNumber from './waste-card-number.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';

export const featureKey = 'addresses';

export interface AddressesState {
  additions: fromAdditions.AdditionsState;
  address: fromAdresses.AddressState;
  wasteCardNumber: fromWasteCardNumber.WasteCardNumberState;
}

export const reducers: ActionReducerMap<AddressesState> = {
  additions: fromAdditions.AdditionsReducer,
  address: fromAdresses.AddressReducer,
  wasteCardNumber: fromWasteCardNumber.WasteCardNumberReducer,
};

export const getAddressesState = createFeatureSelector<AddressesState>(featureKey);

export const metaReducers: MetaReducer<AddressesState>[] = !environment.production ? [] : [];
