import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromWasteCardNumber from '../reducers/waste-card-number.reducer';

export const getWasteCardNumberState = createSelector(
  fromFeature.getAddressesState,
  (state: fromFeature.AddressesState) => state.wasteCardNumber
);

export const getWasteCardNumber = createSelector(getWasteCardNumberState, fromWasteCardNumber.getWasteCardNumber);
export const getWasteCardNumberLoaded = createSelector(getWasteCardNumberState, fromWasteCardNumber.getWasteCardNumberLoaded);
export const getWasteCardNumberLoading = createSelector(getWasteCardNumberState, fromWasteCardNumber.getWasteCardNumberLoading);
export const getWasteCardNumberInitialized = createSelector(getWasteCardNumberState, fromWasteCardNumber.getWasteCardNumberInitialized);
export const getWasteCardNumberInitializing = createSelector(getWasteCardNumberState, fromWasteCardNumber.getWasteCardNumberInitializing);
export const getWasteCardNumberError = createSelector(getWasteCardNumberState, fromWasteCardNumber.getWasteCardNumberError);
