import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromAdditions from '../reducers/additions.reducer';

export const getAdditionsState = createSelector(fromFeature.getAddressesState, (state: fromFeature.AddressesState) => state.additions);

export const getValidAdditions = createSelector(getAdditionsState, fromAdditions.getValidAdditions);
export const getValidAdditionsLoading = createSelector(getAdditionsState, fromAdditions.getValidAdditionsLoading);
export const getValidAdditionsLoaded = createSelector(getAdditionsState, fromAdditions.getValidAdditionsLoaded);

export const getAdditionsTown = createSelector(getAdditionsState, fromAdditions.getAdditionsTown);
export const getAdditionsStreet = createSelector(getAdditionsState, fromAdditions.getAdditionsStreet);
export const getAdditionError = createSelector(getAdditionsState, fromAdditions.getAddressError);
