import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actionsAdditions from '../actions/additions.action';
import * as actions from '../actions/waste-card-number.action';
import { switchMap, map, catchError, tap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Go, getRouterState } from 'src/app/store';
import { AddressesService } from '../../services';

@Injectable()
export class WasteCardNumberEffects {
  private STORAGE_KEY = 'ROVA:WasteCardNumber';

  constructor(
    private actions$: Actions,
    private service: AddressesService,
    private store: Store
  ) {}

  SetWasteCardNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.SetWasteCardNumber),
      switchMap(({ housenumber, addition, postalCode, number }) =>
        this.service.validatePassNumber(housenumber, addition, postalCode, number).pipe(
          map(result =>
            result?.success
              ? actions.SetWasteCardNumberSuccess({ number })
              : actions.SetWasteCardNumberFail({ error: { message: 'Invalid pass number' } })
          ),
          catchError(error => of(actions.SetWasteCardNumberFail({ error })))
        )
      )
    );
  });

  SetWasteCardNumberSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.SetWasteCardNumberSuccess),
        switchMap(WasteCardNumber =>
          this.store.select(getRouterState).pipe(
            take(1),
            tap(({}) => {
              localStorage.setItem(this.STORAGE_KEY, WasteCardNumber.number);
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  ClearWasteCardNumber$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.ClearWasteCardNumber),
        switchMap(() =>
          this.store.select(getRouterState).pipe(
            take(1),
            tap(({}) => {
              localStorage.removeItem(this.STORAGE_KEY);
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  InitWasteCardNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.InitWasteCardNumber),
      map(() => {
        const storedWasteCardNumber = localStorage.getItem(this.STORAGE_KEY);
        if (storedWasteCardNumber) {
          return actions.InitWasteCardNumberSuccess({ number: storedWasteCardNumber });
        }
        return actions.InitWasteCardNumberSuccess({ number: null });
      }),
      catchError(error => of(actions.InitWasteCardNumberFail({ error })))
    );
  });
}
