import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Address } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  loadValidAdditions(postalCode: string, houseNumber: number) {
    return this.http.get<{ street: string; town: string; additions: any[] }>(
      `${this.BASE_URL}api/my-rova/addresses/additions?postalCode=${postalCode}&houseNumber=${houseNumber}`
    );
  }

  getAddress(housenumber: number, addition: string, postalCode: string) {
    return this.http.get<Address>(
      `${this.BASE_URL}api/address?housenumber=${housenumber}&addition=${addition || ''}&postalCode=${postalCode}`
    );
  }

  validatePassNumber(housenumber: string | number, addition: string, postalCode: string, wastecardNumber: string) {
    return this.http.get<{ success: boolean }>(
      `${this.BASE_URL}api/my-rova/addresses/validate?housenumber=${housenumber}&addition=${
        addition || ''
      }&postalCode=${postalCode}&wastecardNumber=${wastecardNumber}`
    );
  }
}
