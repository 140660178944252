import { createReducer, on } from '@ngrx/store';
import { Address } from '../../models';
import * as pageActions from '../actions/address.action';
import * as utility from '../../../utility';

export interface AddressState {
  address: Address | null;
  loaded: boolean;
  loading: boolean;
  initializing: boolean;
  initialized: boolean;
  error?: any;
}

export const initialState: AddressState = {
  address: null,
  loaded: false,
  loading: false,
  initialized: false,
  initializing: false,
};

export const AddressReducer = createReducer(
  initialState,
  on(pageActions.SetAddress, (state: AddressState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(pageActions.SetAddressSuccess, (state: AddressState, { address }) => {
    return {
      ...state,
      address,
      loading: false,
      loaded: true,
      error: undefined,
    };
  }),
  on(pageActions.SetAddressFail, (state: AddressState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(pageActions.InitAddress, (state: AddressState) => {
    return {
      ...state,
      initializing: true,
    };
  }),
  on(pageActions.InitAddressSuccess, (state: AddressState, { address }) => {
    return {
      ...state,
      address,
      loaded: address !== null,
      initializing: false,
      initialized: true,
    };
  }),
  on(pageActions.SetAddressFail, (state: AddressState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
      initializing: false,
      initialized: true,
    };
  }),
  on(pageActions.ClearAddress, (state: AddressState) => {
    return {
      ...state,
      address: null,
      loaded: false,
      loading: false,
      initialized: true,
      initializing: false,
      error: null,
    };
  })
);

export const getAddress = (state: AddressState) => state.address;
export const getAddressLoading = (state: AddressState) => state.loading;
export const getAddressLoaded = (state: AddressState) => state.loaded;
export const getAddressInitializing = (state: AddressState) => state.initializing;
export const getAddressInitialized = (state: AddressState) => state.initialized;
export const getAddressError = (state: AddressState) => state.error;
