import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromAddress from '../reducers/address.reducer';

export const getAddresState = createSelector(fromFeature.getAddressesState, (state: fromFeature.AddressesState) => state.address);

export const getAddress = createSelector(getAddresState, fromAddress.getAddress);
export const getAddressLoaded = createSelector(getAddresState, fromAddress.getAddressLoaded);
export const getAddressLoading = createSelector(getAddresState, fromAddress.getAddressLoading);
export const getAddressInitialized = createSelector(getAddresState, fromAddress.getAddressInitialized);
export const getAddressInitializing = createSelector(getAddresState, fromAddress.getAddressInitializing);
export const getAddressError = createSelector(getAddresState, fromAddress.getAddressError);
