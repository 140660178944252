import { createAction, props } from '@ngrx/store';
import { Address } from '../../models';

export const SetAddress = createAction(
  '[Location] Set User Address',
  props<{ housenumber: number; addition: string; postalCode: string; preventRedirect?: boolean }>()
);

export const SetAddressSuccess = createAction(
  '[Location] Set User Address Success',
  props<{ address: Address; preventRedirect?: boolean }>()
);
export const SetAddressFail = createAction('[Location] Set User Address Fail', props<{ error: any }>());

export const ClearAddress = createAction('[Location] Clear User Address', props<{ redirect?: boolean }>());

export const InitAddress = createAction('[Location] Initialize Address From Local Storage');
export const InitAddressSuccess = createAction(
  '[Location] Initialize Address From Local Storage Success',
  props<{ address: Address | null }>()
);
export const InitAddressFail = createAction('[Location] Initialize Address From Local Storage Fail', props<{ error: any }>());
