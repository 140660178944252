import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actionsAdditions from '../actions/additions.action';
import * as actionsAddress from '../actions/address.action';
import { switchMap, map, catchError, tap, take } from 'rxjs/operators';
import { AddressesService } from '../../services';
import { Store } from '@ngrx/store';
import { Go, getRouterState } from 'src/app/store';

@Injectable()
export class AddressesEffects {
  private STORAGE_KEY = 'ROVA:ADDRESS';

  constructor(
    private actions$: Actions,
    private service: AddressesService,
    private store: Store
  ) {}

  LoadValidAdditions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actionsAdditions.LoadValidAdditions),
      switchMap(({ postalCode, houseNumber }) =>
        this.service.loadValidAdditions(postalCode, houseNumber).pipe(
          map(data => actionsAdditions.LoadValidAdditionsSuccess({ data })),
          catchError(error => of(actionsAdditions.LoadValidAdditionsFail({ error })))
        )
      )
    );
  });

  SetAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actionsAddress.SetAddress),
      switchMap(({ housenumber, addition, postalCode, preventRedirect }) =>
        this.service.getAddress(housenumber, addition, postalCode).pipe(
          map(address => actionsAddress.SetAddressSuccess({ address, preventRedirect })),
          catchError(error => of(actionsAddress.SetAddressFail({ error })))
        )
      )
    );
  });

  //SetAddressSuccess$ = createEffect(
  //  () => {
  //    return this.actions$.pipe(
  //      ofType(actionsAddress.SetAddressSuccess),
  //      tap(({ address }) => {
  //        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(address));
  //        this.r
  //      })
  //    );
  //  },
  //  { dispatch: false }
  //);

  SetAddressSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actionsAddress.SetAddressSuccess),
      switchMap(({ address, preventRedirect }) =>
        this.store.select(getRouterState).pipe(
          take(1),
          map(({ state }) => {
            localStorage.setItem(this.STORAGE_KEY, JSON.stringify(address));
            if (preventRedirect) {
              return Go({ path: [] });
            }
            return Go({ path: [state.queryParams['returnUrl'] || '/dashboard'] });
          })
        )
      )
    );
  });

  ClearAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actionsAddress.ClearAddress),
      switchMap(redirect =>
        this.store
          .select(getRouterState)
          .pipe(take(1))
          .pipe(
            map(({ state }) => {
              localStorage.removeItem(this.STORAGE_KEY);

              if (redirect.redirect) {
                return Go({ path: [state.queryParams['returnUrl'] || '/aanmelden/inloggen'] });
              } else {
                return Go({ path: [] });
              }
            })
          )
      )
    );
  });

  InitAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actionsAddress.InitAddress),
      map(() => {
        const storedAddress = localStorage.getItem(this.STORAGE_KEY);
        if (storedAddress) {
          return actionsAddress.InitAddressSuccess({ address: JSON.parse(storedAddress) });
        }
        return actionsAddress.InitAddressSuccess({ address: null });
      }),
      catchError(error => of(actionsAddress.InitAddressFail({ error })))
    );
  });
}
