import { createAction, props } from '@ngrx/store';

export const LoadValidAdditions = createAction(
  '[My Rova Additions] Load Valid Additions',
  props<{ postalCode: string; houseNumber: number }>()
);
export const LoadValidAdditionsSuccess = createAction(
  '[My Rova Additions] Load Valid Additions success',
  props<{ data: { street: string; town: string; additions: any[] } }>()
);
export const LoadValidAdditionsFail = createAction('[My Rova Additions] Load Valid Additions Fail', props<{ error: any }>());
